import xss from 'xss'
import { mode } from '../constants/mode'
import { propertyType } from '../constants/propertyType'
import {
  defaultMaxPrice,
  defaultMinPrice,
} from '../models/defaultSearchParameterValues'
import {
  countryCodeForCurrency,
  linkBaseFromCountryCode,
} from '../contexts/countryContext'
import {
  ContractLength,
  GeoCoordinates,
  MoveInDates,
  PropertyAlertBuildCriteria,
  PropertyAlertFormCriteria,
  SearchBarFilterValues,
  SearchBillOption,
  SearchBuildCriteria,
  SearchFilterOccupancy,
} from './types'
import countryCode from '../types/CountryCode'
import CountryCode from '../types/CountryCode'
import Location from '../types/Location'
import PropertyType from '../types/PropertyType'
import { SearchQueryParams } from '../types/SearchQueryParams'
import Currency from '../types/Currency'
import { SearchApiCriteria } from '../api/searchApi'

const replacePlusSignsWithSpacesIn = (location: string) => {
  return location.replace(/\+/g, ' ')
}

const parseQueryString = (queryString: string): SearchQueryParams => {
  const query: SearchQueryParams = {
    propertyTypes: [] as PropertyType[],
    bills: [] as SearchBillOption[],
  } as SearchQueryParams

  const pairs = (
    queryString[0] === '?' ? queryString.substring(1) : queryString
  ).split('&')

  pairs.forEach((pair) => {
    const splitPair = pair.split('=')

    const queryPropertyName = decodeURIComponent(
      splitPair[0]
    ) as keyof SearchQueryParams
    const queryPropertyValue = xss(decodeURIComponent(splitPair[1]))

    if (queryPropertyName === 'propertyTypes') {
      query.propertyTypes?.push(queryPropertyValue as PropertyType)
    } else if (queryPropertyName === 'bills') {
      query.bills?.push(queryPropertyValue as SearchBillOption)
    } else {
      ;(query as any)[queryPropertyName] = queryPropertyValue || ''
    }
  })

  if (query.instantBook) {
    return {
      ...query,
      instantBook: query.instantBook === 'true' ? 'true' : undefined,
    }
  }

  if (!query.location) return query

  return {
    ...query,
    location: replacePlusSignsWithSpacesIn(decodeURI(query.location)),
  }
}

export const propertyTypesParamsFrom = (propertyTypes?: PropertyType[]) => {
  if (!propertyTypes) return ''
  if (propertyTypes.length === 0) return ''

  const typesAsQueryStringParams = propertyTypes
    .map((type) => `propertyTypes=${type}`)
    .join('&')

  return `&${typesAsQueryStringParams}`
}

export const billsParamsFrom = (bills?: SearchBillOption[]) => {
  if (!bills) return ''
  if (bills.length === 0) return ''

  const billsAsQueryStringParams = bills
    .map((type) => `bills=${type}`)
    .join('&')

  return `&${billsAsQueryStringParams}`
}

export const occupancyTypeFromEntirePlace = (
  entirePlace: boolean
): SearchFilterOccupancy => {
  return entirePlace
    ? SearchFilterOccupancy.wholeProperty
    : SearchFilterOccupancy.min
}

const getMinPrice = (query: SearchQueryParams) => {
  const minPriceNumber = query.minPrice
    ? parseFloat(query.minPrice)
    : defaultMinPrice

  const maxPriceNumber = getMaxPrice(query)

  if (minPriceNumber > maxPriceNumber) {
    return defaultMinPrice
  }

  if (minPriceNumber < defaultMinPrice) {
    return defaultMinPrice
  }

  return minPriceNumber
}

const getMaxPrice = (query: SearchQueryParams) => {
  const minPriceNumber = query.minPrice
    ? parseFloat(query.minPrice)
    : defaultMinPrice

  let maxPriceNumber = defaultMaxPrice

  if (query.maxPrice) {
    maxPriceNumber = parseFloat(query.maxPrice)
  }

  if (minPriceNumber > maxPriceNumber) {
    return defaultMaxPrice
  }

  if (maxPriceNumber > defaultMaxPrice) {
    return defaultMaxPrice
  }

  return maxPriceNumber
}

const orderPropertyTypes = (selectedPropertyTypes: PropertyType[]) => {
  let orderedPropertyTypes: PropertyType[] = []

  if (selectedPropertyTypes.includes(propertyType.halls)) {
    orderedPropertyTypes.push(propertyType.halls)
  }

  if (selectedPropertyTypes.includes(propertyType.house)) {
    orderedPropertyTypes.push(propertyType.house)
  }

  if (selectedPropertyTypes.includes(propertyType.flat)) {
    orderedPropertyTypes.push(propertyType.flat)
  }

  if (selectedPropertyTypes.includes(propertyType.studio)) {
    orderedPropertyTypes.push(propertyType.studio)
  }

  return orderedPropertyTypes
}

const orderBills = (selectedBills: SearchBillOption[]) => {
  let orderedBills: SearchBillOption[] = []

  // Are the Bills ever needed in lowercase? All these assertions are BAD and will cause future issues

  if (selectedBills.includes(SearchBillOption.none)) {
    return [SearchBillOption.none]
  }

  if (selectedBills.includes(SearchBillOption.gas)) {
    orderedBills.push(SearchBillOption.gas)
  }

  if (selectedBills.includes(SearchBillOption.electricity)) {
    orderedBills.push(SearchBillOption.electricity)
  }

  if (selectedBills.includes(SearchBillOption.water)) {
    orderedBills.push(SearchBillOption.water)
  }

  if (selectedBills.includes(SearchBillOption.internet)) {
    orderedBills.push(SearchBillOption.internet)
  }

  return orderedBills
}

const getMaxPriceForPropertyAlertCriteria = (maxPrice?: string | number) => {
  if (maxPrice && castToNumber(maxPrice) === defaultMaxPrice) {
    return 0
  }
  if (maxPrice) {
    return castToNumber(maxPrice)
  }
  return 0
}

const propertyTypesWithHallsRemoved = (
  propertyTypes: PropertyType[]
): PropertyType[] | [] => {
  return propertyTypes?.filter((t) => t !== propertyType.halls) ?? []
}

export const toSearchPageUrl = (criteria: SearchBuildCriteria) => {
  const linkBase = linkBaseFromCountryCode(criteria.countryCode)

  const location = criteria.location || ''

  const latitudeAndLongitude = criteria.hasGeoCoordinates
    ? `&latitude=${criteria.latitude}&longitude=${criteria.longitude}`
    : ''

  const radius = criteria.radius ? `&radius=${criteria.radius}` : ''

  const instantBook = criteria.instantBook ? '&instantBook=true' : ''

  const propertyTypes = propertyTypesParamsFrom(criteria.propertyTypes)

  const bills = billsParamsFrom(criteria.bills)

  const moveInFrom = criteria.moveInFrom
    ? `&moveInFrom=${criteria.moveInFrom}`
    : ''

  const moveInTo = criteria.moveInTo ? `&moveInTo=${criteria.moveInTo}` : ''

  const minContractLength = criteria.minContractLength
    ? `&minContractLength=${criteria.minContractLength}`
    : ''

  const maxContractLength = criteria.maxContractLength
    ? `&maxContractLength=${criteria.maxContractLength}`
    : ''

  const sorting =
    criteria.sortBy && criteria.order
      ? `&sortBy=${criteria.sortBy}&order=${criteria.order}`
      : ''

  return `${linkBase}/search-results?location=${encodeURI(location)}&beds=${
    criteria.beds
  }&occupancy=${
    criteria.occupancy
  }${instantBook}${propertyTypes}${bills}&minPrice=${
    criteria.minPrice
  }&maxPrice=${criteria.maxPrice}${latitudeAndLongitude}${radius}&geo=${
    criteria.geo
  }${moveInFrom}${moveInTo}${minContractLength}${maxContractLength}${sorting}&page=${
    criteria.page
  }`
}

export const toLandingPageUrl = (criteria: {
  locationPath: any
  propertyType?: any
  areaPath?: any
}) => {
  const { locationPath, areaPath, propertyType } = criteria
  const area = areaPath ? `/${areaPath}` : ''

  let property

  switch (propertyType) {
    case 'halls':
      property = '/student-halls'
      break
    case 'house':
      property = '/student-houses'
      break
    case 'flat':
      property = '/student-flats'
      break
    case 'studio':
      property = '/studios'
      break
    default:
      property = ''
  }

  return `/${locationPath}${area}${property}`
}

export const toSearchServiceCriteria = (
  criteria: SearchBuildCriteria
): SearchApiCriteria => {
  return {
    ...criteria,
    minContractLength: criteria.minContractLength,
    maxContractLength: criteria.maxContractLength,
    location: criteria.locationForSearch,
    numberOfBedrooms: criteria.beds,
    maxPrice:
      criteria.maxPrice === undefined || criteria.maxPrice >= defaultMaxPrice
        ? undefined
        : criteria.maxPrice,
    mode: criteria.geo ? mode.geo : mode.text,
  }
}

const buildCriteria = (
  query:
    | SearchQueryParams
    | Partial<SearchBuildCriteria>
    | SearchBuildCriteria
    | {},
  afsLocations: Location[],
  countryCode: countryCode
): SearchBuildCriteria => {
  let criteria: SearchBuildCriteria = {} as SearchBuildCriteria

  const locationIsDefault = (location?: string) => {
    if (!location) return true
    return location === 'any'
  }

  const shouldPerformGeoSearchFor = (selectedLocation?: string) => {
    if (locationIsDefault(selectedLocation)) return false

    if (!selectedLocation) return true

    const isACity = afsLocations.find((location) =>
      location.possibleNames.includes(selectedLocation)
    )

    return !isACity
  }

  const getGeoCoordinatesOfAfsLocation = (selectedLocation: string) => {
    const location = afsLocations.find((location) =>
      location.possibleNames.includes(selectedLocation)
    )

    return {
      latitude: location?.latitude,
      longitude: location?.longitude,
    }
  }

  const withLocation = (
    selectedLocation: string,
    geoCoordinates?: GeoCoordinates
  ) => {
    if (!selectedLocation) {
      criteria = {
        ...criteria,
        location: '',
        locationForSearch: '',
        latitude: undefined,
        longitude: undefined,
        radius: undefined,
        hasGeoCoordinates: false,
        geo: false,
        page: 1,
      }

      return criteria
    }

    if (shouldPerformGeoSearchFor(selectedLocation)) {
      criteria = {
        ...criteria,
        location: selectedLocation,
        locationForSearch: getLocationForSearch(selectedLocation),
        latitude: geoCoordinates?.latitude,
        longitude: geoCoordinates?.longitude,
        radius: geoCoordinates?.radius,
        hasGeoCoordinates: true,
        geo: true,
        page: 1,
      }

      return criteria
    }

    const { latitude, longitude } =
      getGeoCoordinatesOfAfsLocation(selectedLocation)

    criteria = {
      ...criteria,
      location: selectedLocation,
      locationForSearch: getLocationForSearch(selectedLocation),
      latitude,
      longitude,
      radius: undefined,
      hasGeoCoordinates: true,
      geo: false,
      page: 1,
    }

    return criteria
  }

  const withCountryCode = (selectedCountryCode: CountryCode) => {
    criteria = {
      ...criteria,
      countryCode: selectedCountryCode,
    }

    return criteria
  }

  const withInstantBook = (selectedInstantBook?: boolean) => {
    criteria = {
      ...criteria,
      instantBook: selectedInstantBook || undefined,
    }

    return criteria
  }

  const withPropertyTypes = (selectedPropertyTypes: PropertyType[]) => {
    criteria = {
      ...criteria,
      propertyTypes: orderPropertyTypes(selectedPropertyTypes),
      page: 1,
    }

    return criteria
  }

  const withBills = (selectedBills: SearchBillOption[]) => {
    criteria = {
      ...criteria,
      bills: orderBills(selectedBills),
      page: 1,
    }

    return criteria
  }

  const withMinPrice = (selectedMinPrice: number) => {
    criteria = {
      ...criteria,
      minPrice: selectedMinPrice,
      page: 1,
    }

    return criteria
  }

  const withMaxPrice = (selectedMaxPrice: number) => {
    criteria = {
      ...criteria,
      maxPrice: selectedMaxPrice,
      page: 1,
    }

    return criteria
  }

  const withBeds = (selectedBeds: number) => {
    criteria = {
      ...criteria,
      beds: selectedBeds,
      page: 1,
    }

    return criteria
  }

  const incrementBedsByOne = () => {
    criteria = {
      ...criteria,
      beds: criteria.beds && criteria.beds + 1,
      page: 1,
    }

    return criteria
  }

  const decrementBedsByOne = () => {
    criteria = {
      ...criteria,
      beds: criteria.beds && criteria.beds - 1,
      page: 1,
    }

    return criteria
  }

  const withOccupancy = (selectedOccupancy: SearchFilterOccupancy) => {
    criteria = {
      ...criteria,
      occupancy: selectedOccupancy,
      page: 1,
    }

    return criteria
  }

  const withMoveInDates = (selectedMoveInDates: MoveInDates) => {
    criteria = {
      ...criteria,
      ...selectedMoveInDates,
      page: 1,
    }

    return criteria
  }

  const withContractLength = (selectedContractLength: ContractLength) => {
    criteria = {
      ...criteria,
      ...selectedContractLength,
      page: 1,
    }

    return criteria
  }

  const withFilters = (
    filters: Omit<SearchBarFilterValues, 'location' | 'beds' | 'price'>
  ) => {
    if (filters.propertyTypes) {
      filters.propertyTypes = orderPropertyTypes(filters.propertyTypes)
    }

    if (filters.bills) {
      filters.bills = orderBills(filters.bills)
    }

    criteria = {
      ...criteria,
      ...filters,
      page: 1,
    }

    return criteria
  }

  const withPage = (selectedPage: number) => {
    criteria = {
      ...criteria,
      page: selectedPage,
    }

    return criteria
  }

  const sortByPriceAscending = () => {
    criteria = {
      ...criteria,
      sortBy: 'price',
      order: 'asc',
      page: 1,
    }

    return criteria
  }

  const sortByPriceDescending = () => {
    criteria = {
      ...criteria,
      sortBy: 'price',
      order: 'desc',
      page: 1,
    }

    return criteria
  }

  const sortByDefault = () => {
    criteria = {
      ...criteria,
      sortBy: '',
      order: '',
      page: 1,
    }

    return criteria
  }

  const getLocation = (location?: string) => {
    if (locationIsDefault(location)) {
      return ''
    }

    return location
  }

  const getLocationForSearch = (location?: string) => {
    if (!location || location === 'any') return ''

    const locationWithAnAlias = afsLocations.find((loc) =>
      loc.possibleNames.includes(location)
    )

    if (locationWithAnAlias) {
      return locationWithAnAlias.name
    }

    return location
  }

  criteria = {
    ...query,
    countryCode: countryCode || 'gb',
    location: getLocation('location' in query ? query.location : ''),
    locationForSearch: getLocationForSearch(
      'location' in query ? query.location : ''
    ),
    beds: castToNumber('beds' in query ? query.beds : ''),
    occupancy:
      'occupancy' in query && query.occupancy
        ? query.occupancy
        : SearchFilterOccupancy.min,
    instantBook:
      'instantBook' in query &&
      (query.instantBook === true || query.instantBook === 'true')
        ? true
        : undefined,
    minContractLength:
      'minContractLength' in query && query.minContractLength !== null
        ? Number(query.minContractLength)
        : null,
    maxContractLength:
      'maxContractLength' in query && query.maxContractLength !== null
        ? Number(query.maxContractLength)
        : null,
    propertyTypes:
      'propertyTypes' in query && query.propertyTypes !== undefined
        ? orderPropertyTypes(query.propertyTypes)
        : [],
    bills:
      'bills' in query && query.bills !== undefined
        ? orderBills(query.bills)
        : [],
    minPrice: getMinPrice(query as SearchQueryParams),
    maxPrice: getMaxPrice(query as SearchQueryParams),
    latitude:
      'latitude' in query && query.latitude !== undefined
        ? parseFloat(query.latitude?.toString())
        : undefined,
    longitude:
      'longitude' in query && query.longitude !== undefined
        ? parseFloat(query.longitude?.toString())
        : undefined,
    radius:
      'radius' in query && query.radius
        ? parseFloat(query.radius?.toString())
        : undefined,
    geo: shouldPerformGeoSearchFor('location' in query ? query.location : ''),
    sortBy: 'sortBy' in query ? query.sortBy : '',
    order: 'order' in query ? query.order : '',
    page:
      'page' in query && query.page !== undefined && query.page !== ''
        ? parseFloat(query.page?.toString())
        : 1,
    hasGeoCoordinates:
      'latitude' in query &&
      'longitude' in query &&
      query.latitude !== undefined &&
      query.latitude !== '' &&
      query.longitude !== undefined &&
      query.longitude !== '',
    moveInFrom: 'moveInFrom' in query ? query.moveInFrom : '',
    moveInTo: 'moveInTo' in query ? query.moveInTo : '',
    afsLocations,
    withLocation,
    withCountryCode,
    withInstantBook,
    withPropertyTypes,
    withBills,
    withMinPrice,
    withMaxPrice,
    withBeds,
    incrementBedsByOne,
    decrementBedsByOne,
    withOccupancy,
    withMoveInDates,
    withContractLength,
    withFilters,
    withPage,
    sortByPriceAscending,
    sortByPriceDescending,
    sortByDefault,
  }

  return criteria
}

export const parseSearchCriteriaFrom = (
  queryString: string,
  afsLocations: Location[],
  countryCode: CountryCode
) => {
  const parsed = parseQueryString(queryString) as SearchQueryParams
  return buildCriteria(parsed, afsLocations, countryCode)
}

export const getSearchCriteriaFrom = (
  queryObject: SearchQueryParams,
  afsLocations: Location[],
  countryCode: CountryCode
) => {
  return buildCriteria(queryObject, afsLocations, countryCode)
}

const castToNumber = (param: any) => (isNaN(Number(param)) ? 0 : Number(param))

const buildPropertyAlertCriteria = (
  criteria: PropertyAlertBuildCriteria,
  currency: Currency
): PropertyAlertFormCriteria => {
  return {
    location:
      !criteria.location || criteria.location === 'any'
        ? ''
        : criteria.location,
    numberOfBedrooms: castToNumber(criteria.beds),
    occupancy: criteria.occupancy || SearchFilterOccupancy.min,
    minPrice: {
      amount: castToNumber(criteria.minPrice),
      currency,
    },
    maxPrice: {
      amount: getMaxPriceForPropertyAlertCriteria(criteria.maxPrice),
      currency,
    },
    propertyTypes: criteria.propertyTypes
      ? orderPropertyTypes(criteria.propertyTypes)
      : [],
    coordinates: {
      latitude: castToNumber(criteria.latitude),
      longitude: castToNumber(criteria.longitude),
    },
    radius: castToNumber(criteria.radius),
    geo: !!criteria.geo,
    frequency: 1,
  }
}

export const getPropertyAlertCriteriaFrom = (
  searchCriteria: PropertyAlertBuildCriteria,
  currency: Currency
): PropertyAlertFormCriteria => {
  return buildPropertyAlertCriteria(searchCriteria, currency)
}

export const getSearchCriteriaFromPropertyAlertCriteria = (
  propertyAlertCriteria: PropertyAlertFormCriteria,
  afsLocations: Location[]
) => {
  const validatedCoordinates = () => {
    const { latitude, longitude } = propertyAlertCriteria.coordinates

    if (latitude === 0 && longitude === 0) {
      return {
        latitude: undefined,
        longitude: undefined,
      }
    }

    return {
      latitude,
      longitude,
    }
  }

  const countryCode = countryCodeForCurrency(
    propertyAlertCriteria.minPrice.currency
  )

  const searchQueryFromAlertCriteria: Partial<SearchBuildCriteria> = {
    ...propertyAlertCriteria,
    countryCode,
    beds: propertyAlertCriteria.numberOfBedrooms,
    minPrice: propertyAlertCriteria.minPrice.amount,
    maxPrice: propertyAlertCriteria.maxPrice.amount,
    latitude: validatedCoordinates().latitude,
    longitude: validatedCoordinates().longitude,
    radius: propertyAlertCriteria.radius,
    propertyTypes: propertyAlertCriteria.propertyTypes.map((type) =>
      type.toLowerCase()
    ) as PropertyType[],
  }

  return buildCriteria(searchQueryFromAlertCriteria, afsLocations, countryCode)
}

export const minimumNumberOfBedrooms = 0
export const maximumNumberOfBedrooms = 14
export const defaultNumberOfBedrooms = 0
export const defaultEntirePlace = false
export const defaultMinPrice = 0
export const defaultMaxPrice = 500
export const priceStep = 20
export const defaultMoveInDates = {
  moveInFrom: '',
  moveInTo: '',
}
export const defaultContractLength = {
  minContractLength: null,
  maxContractLength: null,
}
export const defaultPropertyTypes = []
export const defaultBills = []
export const defaultInstantBook = undefined

export type IconNames =
// TODO: put this in the root type folder
'city' | 'university' | 'location' | 'bed' | 'google-logo' | 'gbp' | 'eur' | 'dollar' | 'close' | 'back-arrow' | 'forward-arrow' | 'location-filled' | 'search' | 'property-alert-search' | 'edit' | 'alert-info' | 'chevron-left';
export type IconTypes = 'amenities' | 'search' | 'utility';
interface SvgProps extends Omit<React.SVGAttributes<SVGElement>, 'width' | 'height'> {
  size?: number;
  width?: number;
  height?: number;
}
interface Icons extends SvgProps {
  type: IconTypes;
  name: IconNames;
  pointerEvents?: 'none' | 'all';
  focusable?: boolean;
  ariaHidden?: boolean;
}
export interface TSvg extends Icons {}
const Svg = ({
  type,
  name,
  size = 20,
  width,
  height,
  pointerEvents = 'none',
  focusable = false,
  ariaHidden = true,
  ...rest
}: TSvg) => {
  return <svg xmlns="http://www.w3.org/2000/svg" aria-hidden={ariaHidden} focusable={focusable} pointerEvents={pointerEvents} width={width ? width : size} height={height ? height : size} {...rest} data-sentry-element="svg" data-sentry-component="Svg" data-sentry-source-file="index.tsx">
      <use xlinkHref={`/media/svg-sprites/${type}.svg#${name}`} data-sentry-element="use" data-sentry-source-file="index.tsx"></use>
    </svg>;
};
export default Svg;